.empty__row{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;
}

.mobile-nfts__body .empty__row{
    display: none;
}

.empty__box{
    width: 200px;
    height: 267px;
    border-radius: 20px;
    background: rgba(220, 220, 224, 0.3);
    border: 1px dashed #ECECF1;
}

@media screen and (max-width: 1200px){
    .empty__box{
        height: 267px;
        border-radius: 20px;
    }
}

@media screen and (max-width: 768px) {
    .empty__box{
        width: calc(90% / 3);
        height: 267px;
    }
}

/* @media screen and (max-width: 600px) {
    .empty__box{
        width: calc(90% / 2);
        height: 250px;
    }
} */

@media screen and (max-width: 600px) {
    .empty__box{
        width: calc(90% / 2);
        height: 250px;
    }

}

@media screen and (max-width: 425px) {
    .empty__box{
        width: calc(90% / 2);
        height: 210px;
    }

}

/* @media screen and (max-width: 375px) {
    .empty__box{
        width: calc(90% / 2);
        height: 210px;
    }

} */
/*@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');*/


.approve-modal .modal-dialog{
    margin-top: 30vh;
}
.approve-modal .modal-content{
    align-items: center
}

.content {
  width: 300px;
  height: 300px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content p {
  font-size: 16px;
}

.clip{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  flex-direction: column;
  width: 247px;
  height: 64px;
  background-color: #395FEB;
}

.clip img{
  padding-top: 8px;
  width: 60%;
  height: 60%;
}

.clip p{
  width: 90%;
  text-align: center;
  color: #ffffff;
  font-size: 18px;
}

@keyframes clipMe {
  0%, 100% {clip: rect(0, 277px, 2px, 0);}
  25% {clip: rect(0, 2px, 94px, 0);}
  50% {clip: rect(88px, 277px, 90px, 0);}
  75% {clip: rect(0, 277px, 90px, 269px);}
}

.clip ::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 110%;
  height: 140%;
  margin: -5%;
  box-shadow: inset 0 0 0 2px #395FEB;
  animation: clipMe 3s linear infinite;
  animation-delay: -1.5s;
}

.clip ::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 110%;
  height: 140%;
  margin: -5%;
  box-shadow: inset 0 0 0 2px #395FEB;
  animation: clipMe 3s linear infinite;
}
.mobile-destination__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 22px 10px;
    background: #F7F7F9;
    color: #14161A;
    margin: 12px 0;
    border: 1px solid white;
}
.mobile-destination__tittle{
    font-size: 26px;
    font-weight: 600;
    line-height: 26px;
}
.mobile-destination__subtitle{
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
}
.mobile-destination__header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.mobile-destination__address{
    width: 100%;
}
.mobile-destination__address input{
    width: 100%;
    height: 44px;
    background: #FFFFFF;
    border: 1px solid #E0E4EB;
    box-sizing: border-box;
    border-radius: 40px;
    padding-left: 16px;
}
.mobile-destination__address input:focus{
    background-color: #ffffff;
    border: 1px solid #E0E4EB;
}
.mobile-destination__address input::placeholder{
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #62718A;
}
@media screen and (min-width: 460px) {
    .mobile-destination__container{
        display: none;
    }
}
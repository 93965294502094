.scrollArrows {
    position: absolute;
    bottom: -36px;
    left: 50%;
}
.arrows__wrapper {
    position: relative;
    transform: scale(0.28);
}
.arrows {
    width: 60px;
    height: 72px;
    position: absolute;
    left: 50%;
    margin-left: -30px;
    bottom: 20px;
}

.arrows path {
    stroke: #000000;
    stroke-width: 3;
    fill: transparent;
    stroke-width: 1px;
    animation: arrow 2s infinite;
    -webkit-animation: arrow 2s infinite;
}

@keyframes arrow {
    0% {
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    80% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@-webkit-keyframes arrow /*Safari and Chrome*/ {
    0% {
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    80% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

.arrows path.a1 {
    animation-delay: -1s;
    -webkit-animation-delay: -1s; /* Safari 和 Chrome */
}

.arrows path.a2 {
    animation-delay: -0.5s;
    -webkit-animation-delay: -0.5s; /* Safari 和 Chrome */
}

.arrows path.a3 {
    animation-delay: 0s;
    -webkit-animation-delay: 0s; /* Safari 和 Chrome */
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.26;
  font-family: "Inter", sans-serif;
  color: #fff;
  background: transparent;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin: 0;
  padding: 0;
}

p {
  margin-bottom: 15px;
}

p:last-child {
  margin-bottom: 0;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

ol,
ul,
dl {
  padding: 0;
  margin: 0;
  list-style: none;
}

ol ol,
ul ul,
ol ul,
ul ol {
  padding: 0;
  list-style: none;
}

::-webkit-scrollbar {
  width: 4px;
}

/* Track */
/*::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
/*::-webkit-scrollbar-thumb {
  background: radial-gradient(
    96.34% 96.34% at 59.82% 3.66%,
    #4532b5 0%,
    #36405a 100%
  );
  border-radius: 10px;
}

/* Handle on hover */
/*::-webkit-scrollbar-thumb:hover {
  background: radial-gradient(
    96.34% 96.34% at 59.82% 3.66%,
    #4532b5 0%,
    #36405a 100%
  );
  border-radius: 10px;
}

/* icons Css  */

.icon {
  width: 24px;
  height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
  transition: 0.2s ease all;
  display: inline-block;
  background-position: center center;
  transition: 0.2s ease all;
}

span.connect_chain_icon {
  width: 30px;
  height: 30px;
  background-image: url("assets/img/icon/connect_chain.svg");
  background-size: contain;
  background-repeat: no-repeat;
  transition: 0.2s ease all;
  display: block;
  cursor: pointer;
}

span.connect_chain_icon:hover {
  background-image: url("assets/img/icon/connect_chain_db.svg");
}

.video_icon {
  background-image: url("assets/img/icon/video.svg");
}

.tutorial_links:hover .video_icon {
  background-image: url("assets/img/icon/video_da.svg");
}

.inf_icon {
  width: 20px;
  height: 20px;
  background-image: url("assets/img/icon/info.svg");
}

.tutorial_links:hover .inf_icon,
.inf_icon:hover {
  background-image: url("assets/img/icon/info_da.svg");
}

/* Icon Css End  */

body.dark_them {
  color: #fff;
  background: #22242f;
}

.bridge_select_contain {
  text-align: center;
  padding-top: 80px;
}

.nft_title h1 {
  font-weight: 600;
  font-size: 38px;
  line-height: 116%;
}

.nft_title {
  margin-bottom: 27px;
}

.bridge_connect {
  border: 1px solid #62718a;
  backdrop-filter: blur(42px);
  border-radius: 16px;
  margin-bottom: 26px;
}

.darkBg {
  background: #232833;
}

.bridge_select {
  display: flex;
  align-items: center;
  padding: 23px 17px 23px 24px;
  cursor: pointer;
}

.bridge_select img.chain {
  display: block;
  margin-right: 16px;
}

img.arrow_down {
  display: block;
  margin-left: auto;
}

.bridge_select:hover img.arrow_down {
  filter: brightness(100);
}

span.connec_chain {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1px;
  background: #62718a;
}

.blue_btn {
  color: #fff;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  background: #3e64ed;
  border-radius: 16px;
  height: 58px;
  padding: 5px 15px;
  transition: 0.2s ease all;
}

.blue_btn:hover {
  background: #174aff;
  color: #fff;
}

.bridge_select_box {
  max-width: 326px;
  margin: 0 auto;
}

.connect_bridge_tutorial > a {
  margin: 10px 0;
}

.tutorial_links {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.tutorial_links .icon {
  margin-right: 7px;
}

.col-g {
  color: #95a4bf;
}

.connect_bridge_tutorial {
  padding-top: 10px;
}

.nft_container_area {
  max-width: 1170px;
  margin: 0 auto;
  padding-top: 18px;
  padding-bottom: 100px;
}

.my_nft_are {
  margin: 0 -15px;
  display: flex;
}

.send_nft_box {
  width: 370px;
  padding: 0 15px;
  flex-shrink: 0;
}

.nft_box_left {
  padding: 0 15px;
  flex-grow: 1;
}

h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}

.send_title {
  text-align: center;
}

.center_title {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  min-height: 58px;
}

.sendNftCont {
  padding: 8px 16px 38px;
  box-shadow: 0px 4px 14px rgba(47, 61, 84, 0.05);
  border-radius: 20px;
  height: 100%;
}

h4 {
  font-weight: 600;
  font-size: 16px;
  line-height: 126%;
}

.col-da {
  color: #62718a;
}

.destination_title {
  display: flex;
  align-items: center;
  height: 40px;
  margin-bottom: 12px;
}

.destinatio_logo {
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.destinatio_logo img {
  display: block;
  margin-right: 7px;
}

.inp_des_ad {
}

.nft_destination {
  padding: 0 16px;
  margin-top: 25px;
  margin-bottom: 16px;
}

.destination_address input {
  display: block;
  color: #62718a;
  border: 1px solid;
  height: 40px;
  padding: 5px 12px;
  border-radius: 10px;
  background: #242936;
  width: 100%;
}

.destination_address input::placeholder {
  color: #62718a;
}

.destination_address {
  display: inline-block;
  padding: 1px;
  background: linear-gradient(to right, #4532b5, #36405a);
  border-radius: 10px;
  width: 100%;
}

.destination_address input:focus {
  outline: 1px solid #297eff;
}

.nft_list_title {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
}

.fwn {
  font-weight: 400;
}

li.nft_items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 16px;
  transition: 0.2s ease all;
  cursor: pointer;
}

.nft_items_left {
  display: flex;
  align-items: center;
}

span.nft_icon {
  display: block;
  margin-right: 14px;
}

span.nft_icon img {
  display: block;
  width: 32px;
}

li.nft_items:hover {
  background: rgba(73, 82, 108, 0.1);
}

.remove_nft_tem {
  background: url("assets/img/icon/close_light.svg");
  opacity: 0.7;
  transition: 0.1s ease all;
}

li.nft_items:hover .remove_nft_tem {
  opacity: 1;
}

.nftList_items {
  max-height: 220px;
  overflow: auto;
}

.approve_nft_box {
  padding: 16px;
  margin-top: 11px;
}

.approve_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 28px;
}

.approve_inf {
  position: relative;
  display: block;
  cursor: pointer;
}

.apporve_inf {
  position: absolute;
  z-index: 1;
  right: 50%;
  bottom: 100%;
  margin-bottom: 17px;
  width: 250px;
  background: #262a36;
  border: #2d3342 1px solid;
  border-radius: 6px;
  padding: 12px 12px 22px 12px;
  transform: translate(50%);
  opacity: 0;
  visibility: hidden;
  transition: 0.2s ease all;
}

.apporve_inf:after {
  position: absolute;
  content: "";
  right: 50%;
  top: 100%;
  width: 20px;
  height: 20px;
  border: #2d3342 1px solid;
  background: #262a36;
  transform: translate(50%) rotate(45deg);
  margin-top: -10px;
  border-left: 0;
  border-top: 0;
}

.approve_inf:hover .apporve_inf {
  opacity: 1;
  visibility: visible;
}

.nft_approve_bgn {
  display: flex;
  align-items: center;
  height: 31px;
  margin-top: 12px;
  justify-content: space-between;
}

.approve_btn input {
  display: none;
}

.approve_btn label {
  background: #49526c;
  border-radius: 56px;
  width: 56px;
  height: 31px;
  position: relative;
  transition: 0.3s ease all;
  cursor: pointer;
}

span.approve_box {
  background: #ffffff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  border-radius: 40px;
  width: 24px;
  height: 24px;
  display: block;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  left: 3px;
  transition: 0.3s ease all;
}

.approcheIcon {
  background-image: url("assets/img/icon/check_gray.svg");
  background-size: 12px;
}

.approve_btn input:checked + label .approcheIcon {
  background-image: url(assets/img/icon/check_gren.svg);
}

.approve_btn input:checked + label span.approve_box {
  left: 28px;
}

.approve_btn input:checked + label {
  background: #08d70d;
}

.nft_fees {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 62px;
  padding: 6px 16px;
  margin-top: 12px;
  margin-bottom: 26px;
}

.sendNftBtnBox {
  padding: 0 16px;
}

.sendNftBtnBox .blue_btn {
  background: #297efe;
}

.nft-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
}

.nft-col {
  width: 33.33333%;
  padding: 0 12px;
  margin-bottom: 24px;
}

img {
  max-width: 100%;
}

.nft_cont_left {
  border-radius: 20px;
  position: relative;
  z-index: 1;
  padding: 30px 30px 46px 20px;
}

.nft-cont-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 26px;
}

.seaViewnft {
  display: flex;
  align-items: center;
}

.seaViewnft > div {
  align-items: center;
  margin-left: 8px;
}

.viewNft {
}

.viewNfBtn {
  display: flex;
  width: 40px;
  height: 40px;
  background-image: url("assets/img/icon/view_list.svg");
  background-size: 18px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.2s ease all;
}

.selectAllNft {
  padding: 8px 11px;
}

.viewNfBtn:hover {
  background-color: #2d3340;
}

.gridView {
  display: flex;
  width: 40px;
  height: 40px;
  background-image: url(assets/img/icon/view_grid.svg);
  background-size: 18px;
  border-radius: 10px;
  transition: 0.2s ease all;
}

.searchNft .btn {
  padding: 0;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchNftIcon {
  background-image: url("assets/img/icon/search.svg");
  background-size: 18px;
}

.setting_sidebar .searchNft .dropdown-toggle::after {
  display: none;
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none;
}

.setting_sidebar .searchNft .dropdown-menu {
  right: 0 !important;
  top: 0 !important;
  width: 185px;
  min-width: unset;
  padding: 0;
  left: auto !important;
  transform: unset !important;
  background: transparent;
  border: 0;
  outline: none;
  box-shadow: none;
}

.setting_sidebar .searchNft .dropdown-menu form {
  display: flex;
  height: 40px;
  width: 100%;
  background: rgba(98, 113, 138, 0.1);
  border-radius: 10px;
  overflow: hidden;
  background-color: #2d3340;
  position: relative;
}

.setting_sidebar .searchNft .dropdown-menu form input {
  height: 40px;
  width: 60%;
  flex-grow: 1;
  border: 0;
  outline: none;
  box-shadow: none;
  background: transparent;
  padding: 0 40px 0 15px;
  color: #fff;
  border-radius: 10px;
}

.searchBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  border: 0;
  outline: none;
  padding: 0;
  background-image: url(assets/img/icon/search.svg);
  background-size: 18px;
  background-repeat: no-repeat;
  transition: 0.2s ease all;
  background-position: center center;
  background-color: transparent;
  position: absolute;
  right: 0;
  top: 0;
}

.setting_sidebar .searchNft .dropdown-menu form input:focus {
  outline: 1px solid #297eff;
}

.selectyourNft .btn {
  background: rgba(98, 113, 138, 0.1);
  box-shadow: 0px 1px 9px rgb(55 63 92 / 12%);
  border-radius: 8px;
  padding: 6px;
  display: flex;
  align-items: center;
  color: #fff;
  border: 0;
}

.selectyourNft .btn > img {
  display: block;
  height: 28px;
  margin-right: 8px;
}

.selectyourNft .dropdown-toggle::after {
  border: 0;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
  transition: 0.2s ease all;
  display: inline-block;
  background-position: center center;
  background-image: url("assets/img/icon/arrow_down_small.svg");
  transition: 0.3s ease all;
}

.accordion-header {
  position: relative;
}

.accordion-header button.collapsed::after {
  transform: rotate(0);
  transform: translateY(-50%);
  top: 50%;
}

.accordion-header button::after {
  content: "";
  position: absolute;
  background-image: url("assets/img/icon/arrow_down_small.svg");
  width: 24px;
  height: 24px;

  transform: translateY(50%);
  right: 15px;
  cursor: pointer;
  display: inline-block;
  transform: rotate(180deg);
}

.accordion-header button:hover::after {
  opacity: 0.5;
}

.selectyourNft .btn:hover {
  color: #fff;
}

.selectyourNft .dropdown-toggle:hover::after {
  filter: brightness(100);
}

.yorNftOn {
  display: flex;
  align-items: center;
}

.selectyourNft {
  margin: 0 8px;
}

.reloadNft {
  height: 40px;
}

.reloadBtnNft {
  width: 40px;
  height: 40px;
  border: 0;
  border-radius: 10px;
  background-color: transparent;
  padding: 0;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  background-position: center center;
  background-image: url(assets/img/icon/reload.svg);
  transition: 0.2s ease all;
  background-size: 26px;
}

.reloadBtnNft:hover {
  background-color: rgba(98, 113, 138, 0.1);
}

.nft_box_img {
  position: relative;
  z-index: 1;
  padding-bottom: 100%;
  background: #1e222d;
  border-radius: 20px 20px 0px 0px;
}

.select_nft {
  position: absolute;
  left: 18px;
  top: 18px;
  z-index: 2;
}

.select_nft input {
  width: 17px;
  height: 17px;
  opacity: 0;
  cursor: pointer;
}

.selectNfticon {
  width: 17px;
  height: 17px;
  pointer-events: none;
  background: #fff;
  border-radius: 3px;
}

.select_nft > * {
  position: absolute;
}

.select_nft input:checked + .selectNfticon {
  background-image: url("assets/img/icon/CheckGreen.svg") !important;
  background-size: 100% 100%;
  outline: 1px solid #000;
}

.your_nft_img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.your_nft_img img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.your_nft_box {
  border-radius: 20px;
  background: #292f3f;
  overflow: hidden;
}

.nft_box_cont {
  display: flex;
  justify-content: space-between;
  padding: 8px 10px;
  min-height: 75px;
  align-items: flex-start;
}

button.nft_inf {
  display: block;
  border: 0;
  background: transparent;
  padding: 0;
  outline: none;
  box-shadow: none;
}

.nft_inf:hover .inf_icon {
  filter: brightness(100);
}

h6 {
  font-size: 14px;
  line-height: 140%;
}

.nft_name h6 {
  margin-bottom: 6px;
}

.your_nft_img:after {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: #3e64ed;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease all;
}

.nft_box_img:hover .your_nft_img:after {
  opacity: 0.8;
  visibility: visible;
}

.nft-on-list {
  margin-right: -25px;
  padding-right: 20px;
  max-height: 670px;
  overflow: auto;
}

.setting_sidebar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 300px;
  background: #fff;
  color: #000;
  display: flex;
  flex-direction: column;
  font-family: "Inter", sans-serif !important;
  z-index: 12;
}

.bodyWrap {
  padding-left: 320px;
  overflow: hidden;
}

.site_setting h2 {
  font-weight: 800;
  font-size: 24px;
  line-height: 31px;
  padding: 16px 30px;
}

.genarel_setting h6 {
  color: #000000;
  opacity: 0.5;
  font-weight: 500;
  font-size: 12px;
  line-height: 26px;
  padding: 11px 30px;
}

.setting_list .accordion-button:not(.collapsed) {
  box-shadow: none;
}

.setting_list button.accordion-button {
  background: transparent;
  box-shadow: none !important;
  color: #000;
  min-height: 48px;
  padding: 5px 17px 5px 30px;
  border: 0;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  font-weight: 500;
  font-size: 16px;
  width: 100%;
  text-align: left;
}

.setting_list .accordion-item:nth-child(1) button.accordion-button {
  border-top: 1px solid rgba(224, 228, 235, 0.5);
}

.setting_list .accordion-item {
  border: 0;
}

.accordion-button::after {
  display: block;
  margin: 0;
  margin-left: auto;
  width: 24px;
  height: 24px;
  position: relative;
  z-index: 1;
  background-image: url("./assets/img/icon/arrow_down_black.svg") !important;
  filter: brightness(0);
  background-size: 24px;
}

.smart_con_opt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 55px;
  border-top: 1px solid rgba(224, 228, 235, 0.5);
}

.smart_con_opt p {
  margin: 0;
  color: #000000;
}

.smart_con_opt .approve_btn label {
  border: 1px solid #d4d7dd;
  background: #ebeff4;
}

.smartConertbox .approve_btn input:checked + label {
  background: #07cc0c;
  border: 1px solid #d4d7dd;
}

.setting_list .accordion-body {
  padding: 0;
  border-bottom: 1px solid rgba(224, 228, 235, 0.5);
  background: #f9fafb;
}

.smart_con_opt:first-child {
  border: 0;
}

li.blockChain_item {
  position: relative;
  display: flex;
  align-items: center;
  height: 48px;
  padding: 2px 30px;
  cursor: pointer;
}

li.blockChain_item .select_nft {
  display: block;
  position: relative;
  left: unset;
  top: unset;
  width: 18px;
  height: 18px;
  margin-right: 12px;
}

li.blockChain_item .selectNfticon {
  border: 1px solid #d4d7dd;
}

li.blockChain_item .select_nft input:checked + .selectNfticon {
  outline: none;
  border: 0;
}

.smartConertbox {
  padding: 0 30px;
}

.blockChainItem {
  display: flex;
  align-items: center;
}

.blockChainItem img {
  display: block;
  width: 28px;
  margin-right: 8px;
}

.blockChainCont {
  padding: 12px 0px;
}

li.blockChain_item:hover {
  background: rgba(212, 215, 221, 0.3);
}

.themcolor_sett {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.themcolor_sett h3 {
  font-weight: 500;
}

.them {
  display: flex;
  align-items: center;
}

.them > span {
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
}

.them .approve_btn {
  height: 31px;
  margin: 0 8px;
}

.them .approve_btn input:checked + label {
  background: #3f4144;
  border: 1px solid #d4d7dd;
}

.them .approve_btn label {
  background: #f9fafb;
  /* Outline */
  border: 1px solid #d4d7dd;
}

.them span.approve_box {
  z-index: 3;
}

.setting_list .accordion-item:nth-child(3) .accordion-button::after {
  display: none;
}

.setting_list .accordion-item:nth-child(3) .accordion-button {
  padding-right: 27px;
}

.moon_icon {
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  display: inline-block;
  background-position: center center;
  transition: 0.2s ease all;
  z-index: 1;
  pointer-events: none;
  background-image: url(assets/img/icon/moon.svg);
  left: 5px;
  position: absolute;
  right: auto;
  background-size: 24px;
  top: 50%;
  transform: translateY(-50%);
}

.sun_icon {
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  display: inline-block;
  background-position: center center;
  transition: 0.2s ease all;
  pointer-events: none;
  background-image: url("assets/img/icon/sun.svg");
  z-index: 1;
  position: absolute;
  right: 3px;
  background-size: 24px;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: none;
  filter: unset;
}

.typographyContainer {
  padding: 0 30px;
}

.typographyBox h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 126%;
  padding: 10px 0;
}

.typo-sel h5 {
  font-size: 16px;
  line-height: 160%;
  font-weight: normal;
  color: #000000;
  opacity: 0.6;
  padding: 12px 0 4px;
}

.colorInp {
  width: 26px;
  height: 26px;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}

.select_color input[type="color"] {
  width: 26px;
  height: 26px;
  border: 0;
  padding: 0;
}

.select_color {
  display: flex;
  align-items: center;
  background: #fefefe;
  /* Outline */
  padding: 10px;
  border: 1px solid #d4d7dd;
  border-radius: 8px;
}

.colorCode {
  width: 50%;
  flex-grow: 1;
}

.colorCode input {
  display: block;
  width: 100%;
  border: 0;
  outline: none;
  box-shadow: none;
}

.typo-sel .btn {
  display: block;
  width: 100%;
  background: #ffffff;
  /* Outline */
  border: 1px solid #d4d7dd;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 46px;
  padding: 6px 10px;
  color: #000;
}

.select_font .dropdown-menu {
  width: 100%;
}

.select_font .dropdown-menu a {
  display: block;
  line-height: 26px;
  color: #000;
  font-size: 16px;
}

.sidebar_content {
  max-height: 100%;
  overflow-y: scroll;
  padding-bottom: 50px;
  flex-grow: 1;
}

/* Handle */
.sidebar_content::-webkit-scrollbar-thumb {
  background: #d3d6dc;
}

.typographyBox {
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(224, 228, 235, 0.5);
}

.typographyBox:last-child {
  border: 0;
}

.button_settCont {
  padding: 0 30px;
  padding-bottom: 12px;
}

.cornerRadi input[type="text"] {
  background: #fefefe;
  border: 1px solid #d4d7dd;
  box-sizing: border-box;
  border-radius: 8px;
  height: 46px;
  padding: 0 10px;
}

.export_code {
  padding: 2px 30px 12px 30px;
}

.exportCodeCont {
  background: #fefefe;
  border: 1px solid #d4d7dd;
  border-radius: 8px;
  padding: 10px 50px 10px 10px;
  position: relative;
}

.exportCodeCont p {
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
}

.copyCode.icon {
  border: 0;
  background-color: transparent;
  box-shadow: none;
  outline: none;
  position: absolute;
  right: 10px;
  top: 5px;

  background-image: url("./assets/img/icon/copy-light.svg");
  background-size: 18px;
}

.copyCode.icon:hover {
  background-image: url("./assets/img/icon/copy-gray.svg");
}

.sideFooter {
  margin-top: auto;
  border-top: 1px solid rgba(224, 228, 235, 0.5);
}

.help {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  padding: 10px 30px;
}

.qustion_icon {
  background-image: url("assets/img/icon/question-gray.svg");
}

a.help_icon .qustion_icon {
  opacity: 0.7;
}

a.help_icon:hover .qustion_icon {
  opacity: 1;
}

.powerBySet {
  padding: 10px 30px;
  background: #4a515c;
}
.searchNft .btn-primary {
  background: transparent;
  border: 0;
}

.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.exportCodeCont {
  position: relative;
}

.exportCodeCont span {
  transition: all 0.3s ease;
  position: absolute;
  color: #4465dd;
  top: 5px;
  right: 5px;
}

li.blockChain_item {
  position: relative;
}
li.blockChain_item .blockChainItem span {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  color: #08d70d;
  border: 1px solid #08d70d;
  display: inline-block;
  padding: 5px;
}

#SearchDrop svg {
  position: relative;
  top: 3px;
}

.comingSoon {
  color: grey;
  border-color: gray;
  opacity: 0.9;
  font-size: 10px;
}

.cornerRadi input {
  width: 95%;
}

.genarel_setting {
  position: relative;
  padding-bottom: 10px;
}

.fixed {
  position: fixed;
  width: 300px;
  background-color: #fff;
  z-index: 999;
  border-bottom: 1px solid rgb(240, 236, 236);
}

.expandAll,
.collapseAll {
  position: absolute;
  right: 30px;
  bottom: 10px;
  margin: auto 0;
  width: 20px;
  height: 20px;
  display: inline-block;
  background-position: center center;
  border: 1px solid gainsboro;

  padding: 5px;
  background-image: url("assets/img/icon/arrow_down_small.svg");
  transition: all 0.5s ease;
}

.expandAll:hover,
.collapseAll:hover {
  filter: brightness(85%);
}

.collapseAll {
  right: 5px;

  transform: translateY(0);
  transform: rotate(180deg);
}

.showIframe {
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  background: rgb(231, 227, 227);
  cursor: pointer;
  margin-left: 30px;
  border-radius: 4px;
  color: rgb(19, 17, 17);
  transition: all 0.5s ease;
}

.showIframe:hover {
  color: rgb(19, 17, 17);
  filter: brightness(85%);
}

.inactive {
  pointer-events: none;
  opacity: 0.4;
}

.referalSwitch {
  display: flex;
  align-items: center;
  padding-left: 28px;
  margin-top: 22px;
  cursor: pointer;
  margin-bottom: 15px;
  font-size: 14px;
}

.referalSwitch input {
  cursor: pointer;
  height: 16px;
  width: 16px;
  margin-right: 10px;
}

.bannerOverlay {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(34, 36, 47, 0.94);
  z-index: 99999999999;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.mobileOnlyBanner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
}

.mobileOnlyBanner img {
  margin-top: 28px;
  margin-left: 33px;
  pointer-events: none;
}

.testComp {
  margin-top: 30%;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  pointer-events: none;
}

.testComp h2 {
  font-size: 24px;
  margin-bottom: 16px;
  pointer-events: none;
}

.testComp p {
  display: inline-block;
  width: 264px;
  text-align: center;
  line-height: 28px;
  letter-spacing: 0.5px;
  pointer-events: none;
}

.resetBtn {
  margin-left: 16px;
}

.btn-outline-primary:hover,
.btn-outline-primary:active,
.btn-outline-primary:focus {
  background-color: #fff !important;
  color: #007bff !important;
  box-shadow: none !important;
  outline: none !important;
}

.returnBtn {
  position: absolute;
  top: -30px;
  left: 30px;
  z-index: 9000;
}

.returnBtn button {
  border-radius: 16px;
  border: 1px solid #62718a;
  background: transparent;
  color: #62718a;
  padding: 10px 20px;
}

.selectAllBtn {
  margin-left: 25px;
  padding: 3px 5px;
  font-size: 12px;
  border-color: #28a745;
  color: #28a745;
}

.selectAllBtn.btn-outline-primary:hover,
.selectAllBtn.btn-outline-primary:active,
.selectAllBtn.btn-outline-primary:focus {
  background-color: #fff !important;
  color: #28a745 !important;
  border-color: #28a745 !important;
  box-shadow: none !important;
  outline: none !important;
}

.site_setting {
  position: relative;
}

.collapseImg {
  position: absolute;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 99;
}

.collapseImg.collapsed {
  transform: rotateY(180deg);
  top: 10px;
}

#settingsPortal {
}

.tron-connection-error{
    /* position: relative; */
}

.tron-connection-error-close{
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    z-index: 10;
}


.tron-error-title{
    text-align: center;
    padding: 30px 0 0 0;
}

.tron-login-error__header{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 5vh;
}

.tron-login-error__header img{
    width: 35%;
}

.tron-connection-error__body{
    color: #14161A;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
}

.tron-connection-error__body.install{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.tron-connection-error-button{
    min-width: 230px;
    height: 40px;
    color: white;
    background-color: #3E64ED;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 66px;
}
.tron-connection-error-button:hover{
    color: white;

}
